<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }" :key="$i18n.locale">
    <side-bar :background-color="sidebarBackground" :short-title="$t('app_name')" :title="$t('app_name')" :style="sidebarStyle">
      <template #links>
        <template v-for="(tierOneItem, i) in tierOne">
          <sidebar-item v-if="tierOneItem.show" :key="'menuItem-' + i" :link="{ name: tierOneItem.name, icon: tierOneItem.icon, path: tierOneItem.path }">
            <template :v-if="tierTwo[i]" v-for="(tierTwoItem, j) in tierTwo[i]">
              <sidebar-item
                v-if="tierTwoItem.show"
                :key="'menuItem-' + i + '-' + j"
                :link="{ name: tierTwoItem.name, icon: tierTwoItem.icon, path: tierTwoItem.path }"
              />
            </template>
          </sidebar-item>
        </template>
        <b-button v-if="$cookies.get('Store') == 2" variant="danger" class="mt-auto mb-4 mx-4" @click="clearCache"> Clear Cache </b-button>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground" :style="mainContentStyle" :key="tzKey">
      <dashboard-navbar />

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in" v-if="$route.meta.keepAlive">
          <keep-alive><router-view :key="$route.fullPath" /></keep-alive>
        </fade-transition>
        <fade-transition :duration="200" origin="center top" mode="out-in" v-else>
          <router-view />
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
import routerMixin from '@/mixins/router-mixin'
import axios from 'axios'
import { FadeTransition } from 'vue2-transitions'
import { mapGetters } from 'vuex'
import vueAuth from '../plugins/authenticate'
import DashboardNavbar from './DashboardNavbar.vue'

export default {
  mixins: [
    routerMixin, // hasPermission
  ],
  components: { DashboardNavbar, FadeTransition },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
    }
  },
  computed: {
    ...mapGetters({ sidebarOpen: 'settings/sidebarOpen', localTz: 'settings/timezone', selfStore: 'store', allowMultiStores: 'allowMultiStores' }),
    tzKey() {
      return this.localTz?.replace('/', '-').toLowerCase() ?? ''
    },
    sidebarStyle() {
      return this.sidebarOpen ? '' : 'width: 60px;z-index:1'
    },
    mainContentStyle() {
      return this.sidebarOpen ? '' : 'margin-left:60px;z-index: 0'
    },
    tierOne() {
      return [
        { show: true, name: this.$t('sidebar.dashboard'), icon: 'house', path: '/dashboard' },
        { show: true, name: this.$t('sidebar.competitions'), icon: 'trophy' },
        { show: this.hasPermission(51), name: this.$t('sidebar.orders'), icon: 'minecart-loaded', path: '/orders' },
        {
          show: this.hasPermission(99) && (this.selfStore == 1 || this.allowMultiStores),
          name: this.$t('sidebar.topup_payments'),
          icon: 'credit-card',
          path: '/topup-payments',
        },
        { show: true, name: this.$t('sidebar.reports'), icon: 'bar-chart' },
        { show: this.hasPermission(12), name: this.$t('sidebar.users'), icon: 'person', path: '/users' },
        {
          show: (this.hasPermission(103) || this.hasPermission(134)) && (this.selfStore == 1 || this.allowMultiStores),
          name: this.$t('sidebar.user_wallet'),
          icon: 'wallet2',
        },
        { show: true, name: this.$t('sidebar.admins'), icon: 'person-badge' },
        { show: true, name: this.$t('sidebar.page_manager'), icon: 'images' },
        { show: true, name: this.$t('sidebar.settings'), icon: 'gear' },
        { show: this.hasPermission(152), name: this.$t('sidebar.affiliates'), icon: 'people' },
      ]
    },
    tierTwo() {
      return [
        null,
        [
          { show: this.hasPermission(40), name: this.$t('sidebar.product_management'), path: '/competitions/product-management', icon: 'gem' },
          { show: this.hasPermission(18), name: this.$t('sidebar.competition_list'), path: '/competitions/competition-list', icon: 'gem' },
        ],
        null,
        null,
        [
          { show: this.hasPermission(87), name: this.$t('sidebar.sales_report'), path: '/reports/sales-report' },
          { show: this.hasPermission(88), name: this.$t('sidebar.competition_report'), path: '/reports/competition-report' },
          { show: this.hasPermission(88), name: this.$t('sidebar.monthly_competition_report'), path: '/reports/monthly-competition-report' },
          { show: this.hasPermission(118), name: this.$t('sidebar.users_competitions'), path: '/reports/users-competitions' },
          { show: this.hasPermission(121), name: this.$t('sidebar.users_with_purchase'), path: '/reports/users-with-purchase' },
          { show: this.hasPermission(122), name: this.$t('sidebar.stock_report'), path: '/reports/stock-report' },
          { show: this.hasPermission(125), name: this.$t('sidebar.re_enter_report'), path: '/reports/re-enter-report' },
          { show: this.hasPermission(88), name: this.$t('sidebar.instant_win_live_revenue_report'), path: '/reports/instant-win-live-revenue-report' },
          { show: this.hasPermission(142), name: this.$t('sidebar.multi_pay_report'), path: '/reports/multi-pay-report' },
          { show: this.hasPermission(152), name: this.$t('sidebar.affiliate_report'), path: '/reports/affiliate-report' },
        ],
        null,
        [
          { show: this.hasPermission(103), name: this.$t('sidebar.credit_history'), path: '/user-wallet/credit-history' },
          { show: this.hasPermission(134), name: this.$t('sidebar.cash_history'), path: '/user-wallet/cash-history' },
        ],
        [
          { show: this.hasPermission(9), name: this.$t('sidebar.admin_management'), path: '/admins/admin-management' },
          { show: this.hasPermission(10), name: this.$t('sidebar.role_and_permission'), path: '/admins/role-and-permission' },
          { show: this.hasPermission(124), name: this.$t('sidebar.action_logs'), path: '/admins/action-logs' },
        ],
        [
          { show: this.hasPermission(68), name: this.$t('sidebar.entry_lists'), path: '/page-manager/entry-lists' },
          { show: this.hasPermission(73), name: this.$t('sidebar.video_draws'), path: '/page-manager/video-draws' },
          { show: this.hasPermission(77), name: this.$t('sidebar.winners'), path: '/page-manager/winners' },
          { show: this.hasPermission(84), name: this.$t('sidebar.donations'), path: '/page-manager/donations' },
        ],
        [
          { show: this.hasPermission(130), name: this.$t('sidebar.competition_approval'), path: '/settings/competition-approval' },
          { show: this.hasPermission(111), name: this.$t('sidebar.banners'), path: '/settings/banners' },
          { show: this.hasPermission(59), name: this.$t('sidebar.coupons'), path: '/settings/coupons' },
          { show: this.hasPermission(36), name: this.$t('sidebar.catalogs'), path: '/settings/catalogs' },
          {
            show: this.hasPermission(101) && (this.selfStore == 1 || this.allowMultiStores),
            name: this.$t('sidebar.cashback_config'),
            path: '/settings/cashback-config',
          },
          {
            show: this.hasPermission(97) && (this.selfStore == 1 || this.allowMultiStores),
            name: this.$t('sidebar.topup_config'),
            path: '/settings/topup-config',
          },
          { show: this.hasPermission(107), name: this.$t('sidebar.payment_config'), path: '/settings/payment-config' },
          { show: this.hasPermission(143), name: this.$t('sidebar.database_config'), path: '/settings/database-config' },
        ],
        [
          { show: this.hasPermission(152), name: this.$t('sidebar.affiliate_management'), path: '/affiliates/affiliate-management' },
          { show: this.hasPermission(152), name: this.$t('sidebar.campaign_management'), path: '/affiliates/campaign-management' },
          { show: this.hasPermission(152), name: this.$t('sidebar.referral_management'), path: '/affiliates/referral-management' },
          { show: this.hasPermission(152), name: this.$t('sidebar.transactions_management'), path: '/affiliates/transactions-management' },
        ],
      ]
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    clearCache() {
      axios
        .post('https://raffle-api.mexplay.mx/admin/cache-clear', undefined, { headers: { ['Authorization']: vueAuth.getToken() } })
        .then(res => {
          if (res?.status == 200) {
            this.$notify({ group: 'root', type: 'success', title: 'Success', text: 'Clear server cache successfully!' })
          } else {
            this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') })
          }
        })
        .catch(() => this.$notify({ group: 'root', type: 'error', title: 'Error', text: this.$t('notify.unknown_err') }))
    },
  },
  created() {
    // initTz
    if (!this.localTz) {
      this.$store.dispatch('settings/changeTimezone', this.$moment.tz.guess())
    }
  },
}
</script>
