import auth from './middleware/auth'

export default [
  {
    permissionId: null,
    path: '/dashboard',
    name: 'dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Dashboard/Dashboard.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 40,
    path: '/competitions/product-management',
    name: 'Product List',
    component: () => import('../views/Competitions/ProductManagement/ProductList.vue'),
    meta: { middleware: auth, keepAlive: true },
  },
  {
    permissionId: 42,
    path: '/competitions/product-management/create',
    name: 'Product Create',
    component: () => import('../views/Competitions/ProductManagement/ProductCreate.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 43,
    path: '/competitions/product-management/edit',
    name: 'Product Edit',
    component: () => import('../views/Competitions/ProductManagement/ProductEdit.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 18,
    path: '/competitions/competition-list',
    name: 'Competition List',
    component: () => import('../views/Competitions/CompetitionManagement/CompetitionList.vue'),
    meta: { middleware: auth, keepAlive: true },
  },
  {
    permissionId: 24,
    path: '/competitions/competition-list/create',
    name: 'Competition Create',
    component: () => import('../views/Competitions/CompetitionManagement/CompetitionCreate.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 25,
    path: '/competitions/competition-list/edit',
    name: 'Competition Edit',
    component: () => import('../views/Competitions/CompetitionManagement/CompetitionEdit.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 19,
    path: '/competitions/competition-list/details',
    name: 'Competition Details',
    component: () => import('../views/Competitions/CompetitionManagement/CompetitionDetails.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 28,
    path: '/competitions/competition-list/tickets',
    name: 'Competition Tickets',
    component: () => import('../views/Competitions/CompetitionManagement/CompetitionTickets.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 133,
    path: '/competitions/competition-list/prize-list',
    name: 'Prize List',
    component: () => import('../views/Competitions/CompetitionManagement/PrizeList.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 51,
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/Orders/OrderManagement.vue'),
    meta: { middleware: auth, keepAlive: true },
  },
  {
    permissionId: 52,
    path: '/orders/details',
    name: 'Order Details',
    component: () => import('../views/Orders/OrderDetails.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 99,
    path: '/topup-payments',
    name: 'Topup Payments',
    component: () => import('../views/TopupPayment/TopupPayment.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 87,
    path: '/reports/sales-report',
    name: 'Sales Report',
    component: () => import('../views/Report/SalesReport.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 88,
    path: '/reports/competition-report',
    name: 'Competition Report',
    component: () => import('../views/Report/CompetitionReport.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 88,
    path: '/reports/monthly-competition-report',
    name: 'Monthly Competition Report',
    component: () => import('../views/Report/MonthlyCompetitionReport.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 125,
    path: '/reports/re-enter-report',
    name: 'Re-Enter Report',
    component: () => import('../views/Report/ReEnterWeeklyReport.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 88,
    path: '/reports/instant-win-live-revenue-report',
    name: 'Instant Win Live Revenue Report',
    component: () => import('../views/Report/InstantWinLiveRevenueReport.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  // {
  //   permissionId: 87,
  //   path: '/reports/competition-daily-report',
  //   name: 'Competition Daily Report',
  //   component: () => import('./views/Report/CompetitionDailyReport.vue'),
  //   meta: { middleware: auth, keepAlive: false },
  // },
  // {
  //     permissionId: 90,
  //     path: '/reports/customer-report',
  //     name: 'Customer Report',
  //     component: () => import('../views/Report/CustomerReport.vue'),
  //     meta: { middleware: auth, keepAlive: false },
  // },
  {
    permissionId: 142,
    path: '/reports/multi-pay-report',
    name: 'Multiple Payments Report',
    component: () => import('../views/Report/MultiPayReport.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 118,
    path: '/reports/users-competitions',
    name: 'Users Competitions',
    component: () => import('../views/Report/UsersCompetitionsReport.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 121,
    path: '/reports/users-with-purchase',
    name: 'Users With Purchase',
    component: () => import('../views/Report/UsersPurchaseReport.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 122,
    path: '/reports/stock-report',
    name: 'Stock Report',
    component: () => import('../views/Report/StockReport.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 12,
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users/UserManagement.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 103,
    path: '/user-wallet/credit-history',
    name: 'Credit History',
    component: () => import('../views/CreditHistory.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 134,
    path: '/user-wallet/cash-history',
    name: 'Cash History',
    component: () => import('../views/CashHistory.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 9,
    path: '/admins/admin-management',
    name: 'Admin Management',
    component: () => import('../views/Admin/AdminManagement.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 10,
    path: '/admins/role-and-permission',
    name: 'Role and Permission',
    component: () => import('../views/Admin/RolePermission.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 124,
    path: '/admins/action-logs',
    name: 'Action Logs',
    component: () => import('../views/Admin/ActionLogs.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 84,
    path: '/page-manager/donations',
    name: 'Donations',
    component: () => import('../views/PageManager/Donation.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 68,
    path: '/page-manager/entry-lists',
    name: 'Entry Lists',
    component: () => import('../views/PageManager/EntryList.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 73,
    path: '/page-manager/video-draws',
    name: 'Video Draws',
    component: () => import('../views/PageManager/VideoList.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 77,
    path: '/page-manager/winners',
    name: 'Winner List',
    component: () => import('../views/PageManager/Winners/WinnerList.vue'),
    meta: { middleware: auth, keepAlive: true },
  },
  {
    permissionId: 78,
    path: '/page-manager/winners/details',
    name: 'Winner Details',
    component: () => import('../views/PageManager/Winners/WinnerDetails.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 130,
    path: '/settings/competition-approval',
    name: 'Competition Approval',
    component: () => import('../views/Settings/CompetitionApproval.vue'),
    meta: { middleware: auth, keepAlive: true },
  },
  {
    permissionId: 111,
    path: '/settings/banners',
    name: 'Banners',
    component: () => import('../views/Settings/Banners/BannerList.vue'),
    meta: { middleware: auth, keepAlive: true },
  },
  {
    permissionId: 113,
    path: '/settings/banners/create',
    name: 'Banner Create',
    component: () => import('../views/Settings/Banners/BannerCreate.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 113,
    path: '/settings/banners/edit',
    name: 'Banner Edit',
    component: () => import('../views/Settings/Banners/BannerEdit.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 59,
    path: '/settings/coupons',
    name: 'Coupons',
    component: () => import('../views/Settings/Coupons/CouponList.vue'),
    meta: { middleware: auth, keepAlive: true },
  },
  {
    permissionId: 64,
    path: '/settings/coupons/coupon-code-list',
    name: 'Coupon Code List',
    component: () => import('../views/Settings/Coupons/CouponCodeList.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 36,
    path: '/settings/catalogs',
    name: 'Catalogs',
    component: () => import('../views/Settings/CatalogConfig.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 101,
    path: '/settings/cashback-config',
    name: 'Cashback Config',
    component: () => import('../views/Settings/CashbackConfig.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 97,
    path: '/settings/topup-config',
    name: 'Topup Config',
    component: () => import('../views/Settings/TopupConfig.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 107,
    path: '/settings/payment-config',
    name: 'Payment Config',
    component: () => import('../views/Settings/TabConfig.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 143,
    path: '/settings/database-config',
    name: 'Database Config',
    component: () => import('../views/Settings/DatabaseConfig.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 152,
    path: '/affiliates/affiliate-management',
    name: 'Affiliate Management',
    component: () => import('../views/Affiliates/AffiliateManagement.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 152,
    path: '/affiliates/referral-management',
    name: 'Referral Management',
    component: () => import('../views/Affiliates/AffiliateReferralManagement.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 152,
    path: '/affiliates/transactions-management',
    name: 'Transactions Management',
    component: () => import('../views/Affiliates/AffiliateTransactionManagement.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 152,
    path: '/affiliates/campaign-management',
    name: 'Campaign Management',
    component: () => import('../views/Affiliates/AffiliateCampaignManagement.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
  {
    permissionId: 152,
    path: '/reports/affiliate-report',
    name: 'Affiliates Report',
    component: () => import('../views/Report/AffiliateReport.vue'),
    meta: { middleware: auth, keepAlive: false },
  },
]
